@import 'src/themes/custom/fonts.css';
@import 'primeng/resources/themes/aura-light-noir/theme.css';

:root {
  font-family: 'Geist', sans-serif;
  font-feature-settings: 'cv02', 'cv03', 'cv04', 'cv11';
  font-variation-settings: normal;
  --font-family: 'Inter var', sans-serif;
  --font-feature-settings: 'cv02', 'cv03', 'cv04', 'cv11';
  --text-color: var(--color-dark-100) !important;
  --text-color-secondary: var(--color-dark-70) !important;
  --primary-color: var(--color-dark-100) !important;
  --primary-color-text: var(--color-light-100) !important;
  --color-light-100-forever: var(--color-light-100-forever);
  --surface-0: var(--color-light-100) !important;
  --surface-50: var(--color-dark-3);
  --surface-100: #f1f5f9;
  --surface-200: #e2e8f0;
  --surface-300: #cbd5e1;
  --surface-400: #94a3b8;
  --surface-500: #64748b;
  --surface-600: #475569;
  --surface-700: #334155;
  --surface-800: #1e293b;
  --surface-900: #0f172a;
  --surface-950: var(--color-dark-100) !important;
  --gray-0: var(--color-light-100) !important;
  --gray-50: var(--color-dark-3);
  --gray-100: #f1f5f9;
  --gray-200: #e2e8f0;
  --gray-300: #cbd5e1;
  --gray-400: #94a3b8;
  --gray-500: #64748b;
  --gray-600: #475569;
  --gray-700: #334155;
  --gray-800: #1e293b;
  --gray-900: #0017fa;
  --gray-950: var(--color-dark-100) !important;
  --content-padding: 1.125rem;
  --inline-spacing: 0.5rem;
  --border-radius: 12px;
  --surface-ground: var(--color-dark-3);
  --surface-section: var(--color-light-100) !important;
  --surface-card: var(--color-light-100) !important;
  --surface-overlay: var(--color-light-100) !important;
  --surface-border: #e2e8f0;
  --surface-hover: #f1f5f9;
  --focus-ring: none;
  --maskbg: rgba(0, 0, 0, 0.4);
  --highlight-bg: var(--color-dark-100) !important;
  --highlight-text-color: var(--color-light-100) !important;
  --p-anchor-gutter: 2px;
  color-scheme: light;

  h1 {
    color: var(--color-dark-100) !important;
  }

  h2 {
    color: var(--color-dark-80) !important;
  }

  p {
    color: var(--color-dark-70) !important;
  }

  /* Colors Light Forever*/
  .color-light-100-forever {
    color: var(--color-light-100-forever) !important;
  }

  /* Colors Dark Forever*/
  .color-dark-100-forever {
    color: var(--color-dark-100-forever) !important;
  }

  /* Colors Light */
  .color-light-100 {
    color: var(--color-light-100) !important;
  }

  /* Colors Dark */
  .color-dark-100 {
    color: var(--color-dark-100) !important;
  }

  .color-light-90 {
    color: var(--color-light-90) !important;
  }

  .color-light-80 {
    color: var(--color-light-80) !important;
  }

  .color-light-70 {
    color: var(--color-light-70) !important;
  }

  .color-light-60 {
    color: var(--color-light-60) !important;
  }

  .color-light-50 {
    color: var(--color-light-50) !important;
  }

  .color-light-40 {
    color: var(--color-light-40) !important;
  }

  .color-light-30 {
    color: var(--color-light-30) !important;
  }

  .color-light-20 {
    color: var(--color-light-20) !important;
  }

  .color-light-10 {
    color: var(--color-light-10) !important;
  }

  .color-light-3 {
    color: var(--color-light-3) !important;
  }

  .color-dark-90 {
    color: var(--color-dark-90) !important;
  }

  .color-dark-80 {
    color: var(--color-dark-80) !important;
  }

  .color-dark-70 {
    color: var(--color-dark-70) !important;
  }

  .color-dark-60 {
    color: var(--color-dark-60) !important;
  }

  .color-dark-50 {
    color: var(--color-dark-50) !important;
  }

  .color-dark-40 {
    color: var(--color-dark-40) !important;
  }

  .color-dark-30 {
    color: var(--color-dark-30) !important;
  }

  .color-dark-20 {
    color: var(--color-dark-20) !important;
  }

  .color-dark-10 {
    color: var(--color-dark-10) !important;
  }

  .color-dark-3 {
    color: var(--color-dark-3) !important;
  }

  /* Colors Primary */
  .color-primary-100 {
    color: var(--color-primary-100) !important;
  }

  .color-primary-90 {
    color: var(--color-primary-90) !important;
  }

  .color-primary-80 {
    color: var(--color-primary-80) !important;
  }

  .color-primary-70 {
    color: var(--color-primary-70) !important;
  }

  .color-primary-60 {
    color: var(--color-primary-60) !important;
  }

  .color-primary-50 {
    color: var(--color-primary-50) !important;
  }

  .color-primary-40 {
    color: var(--color-primary-40) !important;
  }

  .color-primary-30 {
    color: var(--color-primary-30) !important;
  }

  .color-primary-20 {
    color: var(--color-primary-20) !important;
  }

  .color-primary-10 {
    color: var(--color-primary-10) !important;
  }

  .color-primary-5 {
    color: var(--color-primary-5) !important;
  }

  .color-primary-3 {
    color: var(--color-primary-3) !important;
  }

  /* Colors Secondary */
  .color-secondary-100 {
    color: var(--color-secondary-100) !important;
  }

  .color-secondary-90 {
    color: var(--color-secondary-90) !important;
  }

  .color-secondary-80 {
    color: var(--color-secondary-80) !important;
  }

  .color-secondary-70 {
    color: var(--color-secondary-70) !important;
  }

  .color-secondary-60 {
    color: var(--color-secondary-60) !important;
  }

  .color-secondary-50 {
    color: var(--color-secondary-50) !important;
  }

  .color-secondary-40 {
    color: var(--color-secondary-40) !important;
  }

  .color-secondary-30 {
    color: var(--color-secondary-30) !important;
  }

  .color-secondary-20 {
    color: var(--color-secondary-20) !important;
  }

  .color-secondary-10 {
    color: var(--color-secondary-10) !important;
  }

  /* Colors Warning */
  .color-warning-100 {
    color: var(--color-warning-100) !important;
  }

  .color-warning-90 {
    color: var(--color-warning-90) !important;
  }

  .color-warning-80 {
    color: var(--color-warning-80) !important;
  }

  .color-warning-70 {
    color: var(--color-warning-70) !important;
  }

  .color-warning-60 {
    color: var(--color-warning-60) !important;
  }

  .color-warning-50 {
    color: var(--color-warning-50) !important;
  }

  .color-warning-40 {
    color: var(--color-warning-40) !important;
  }

  .color-warning-30 {
    color: var(--color-warning-30) !important;
  }

  .color-warning-20 {
    color: var(--color-warning-20) !important;
  }

  .color-warning-10 {
    color: var(--color-warning-10) !important;
  }

  /* Colors danger */
  .color-danger-100 {
    color: var(--color-danger-100) !important;
  }

  .color-danger-90 {
    color: var(--color-danger-90) !important;
  }

  .color-danger-80 {
    color: var(--color-danger-80) !important;
  }

  .color-danger-70 {
    color: var(--color-danger-70) !important;
  }

  .color-danger-60 {
    color: var(--color-danger-60) !important;
  }

  .color-danger-50 {
    color: var(--color-danger-50) !important;
  }

  .color-danger-40 {
    color: var(--color-danger-40) !important;
  }

  .color-danger-30 {
    color: var(--color-danger-30) !important;
  }

  .color-danger-20 {
    color: var(--color-danger-20) !important;
  }

  .color-danger-10 {
    color: var(--color-danger-10) !important;
  }

  /* Colors Fancy [Light mode] */
  .color-fancy-lime {
    color: var(--color-fancy-lime) !important;
  }

  .color-fancy-sky {
    color: var(--color-fancy-sky) !important;
  }

  .color-fancy-pink {
    color: var(--color-fancy-pink) !important;
  }

  /* Border Colors Light */
  .border-light-100 {
    border-color: var(--color-light-100) !important;
  }

  /* Border Colors Dark */
  .border-dark-100 {
    border-color: var(--color-dark-100) !important;
  }

  .border-dark-90 {
    border-color: var(--color-dark-90) !important;
  }

  .border-dark-80 {
    border-color: var(--color-dark-80) !important;
  }

  .border-dark-70 {
    border-color: var(--color-dark-70) !important;
  }

  .border-dark-60 {
    border-color: var(--color-dark-60) !important;
  }

  .border-dark-50 {
    border-color: var(--color-dark-50) !important;
  }

  .border-dark-40 {
    border-color: var(--color-dark-40) !important;
  }

  .border-dark-30 {
    border-color: var(--color-dark-30) !important;
  }

  .border-dark-20 {
    border-color: var(--color-dark-20) !important;
  }

  .border-dark-10 {
    border-color: var(--color-dark-10) !important;
  }

  .border-dark-3 {
    border-color: var(--color-dark-3) !important;
  }

  /* Border Colors Primary */
  .border-primary-100 {
    border-color: var(--color-primary-100) !important;
  }

  .border-primary-90 {
    border-color: var(--color-primary-90) !important;
  }

  .border-primary-80 {
    border-color: var(--color-primary-80) !important;
  }

  .border-primary-70 {
    border-color: var(--color-primary-70) !important;
  }

  .border-primary-60 {
    border-color: var(--color-primary-60) !important;
  }

  .border-primary-50 {
    border-color: var(--color-primary-50) !important;
  }

  .border-primary-40 {
    border-color: var(--color-primary-40) !important;
  }

  .border-primary-30 {
    border-color: var(--color-primary-30) !important;
  }

  .border-primary-20 {
    border-color: var(--color-primary-20) !important;
  }

  .border-primary-10 {
    border-color: var(--color-primary-10) !important;
  }

  .border-primary-3 {
    border-color: var(--color-primary-3) !important;
  }

  /* Border Colors Secondary */
  .border-secondary-100 {
    border-color: var(--color-secondary-100) !important;
  }

  .border-secondary-90 {
    border-color: var(--color-secondary-90) !important;
  }

  .border-secondary-80 {
    border-color: var(--color-secondary-80) !important;
  }

  .border-secondary-70 {
    border-color: var(--color-secondary-70) !important;
  }

  .border-secondary-60 {
    border-color: var(--color-secondary-60) !important;
  }

  .border-secondary-50 {
    border-color: var(--color-secondary-50) !important;
  }

  .border-secondary-40 {
    border-color: var(--color-secondary-40) !important;
  }

  .border-secondary-30 {
    border-color: var(--color-secondary-30) !important;
  }

  .border-secondary-20 {
    border-color: var(--color-secondary-20) !important;
  }

  .border-secondary-10 {
    border-color: var(--color-secondary-10) !important;
  }

  /* Border Colors Warning */
  .border-warning-100 {
    border-color: var(--color-warning-100) !important;
  }

  .border-warning-90 {
    border-color: var(--color-warning-90) !important;
  }

  .border-warning-80 {
    border-color: var(--color-warning-80) !important;
  }

  .border-warning-70 {
    border-color: var(--color-warning-70) !important;
  }

  .border-warning-60 {
    border-color: var(--color-warning-60) !important;
  }

  .border-warning-50 {
    border-color: var(--color-warning-50) !important;
  }

  .border-warning-40 {
    border-color: var(--color-warning-40) !important;
  }

  .border-warning-30 {
    border-color: var(--color-warning-30) !important;
  }

  .border-warning-20 {
    border-color: var(--color-warning-20) !important;
  }

  .border-warning-10 {
    border-color: var(--color-warning-10) !important;
  }

  /* Border Colors Danger */
  .border-danger-100 {
    border-color: var(--color-danger-100) !important;
  }

  .border-danger-90 {
    border-color: var(--color-danger-90) !important;
  }

  .border-danger-80 {
    border-color: var(--color-danger-80) !important;
  }

  .border-danger-70 {
    border-color: var(--color-danger-70) !important;
  }

  .border-danger-60 {
    border-color: var(--color-danger-60) !important;
  }

  .border-danger-50 {
    border-color: var(--color-danger-50) !important;
  }

  .border-danger-40 {
    border-color: var(--color-danger-40) !important;
  }

  .border-danger-30 {
    border-color: var(--color-danger-30) !important;
  }

  .border-danger-20 {
    border-color: var(--color-danger-20) !important;
  }

  .border-danger-10 {
    border-color: var(--color-danger-10) !important;
  }

  /* Border Colors Fancy */
  .border-fancy-lime {
    border-color: var(--color-fancy-lime) !important;
  }

  .border-fancy-sky {
    border-color: var(--color-fancy-sky) !important;
  }

  .border-fancy-pink {
    border-color: var(--color-fancy-pink) !important;
  }

  /* Background Colors Light */
  .bg-light-100 {
    background-color: var(--color-light-100) !important;
  }

  /* Background Colors Dark */
  .bg-dark-100 {
    background-color: var(--color-dark-100) !important;
  }

  .bg-dark-90 {
    background-color: var(--color-dark-90) !important;
  }

  .bg-dark-80 {
    background-color: var(--color-dark-80) !important;
  }

  .bg-dark-70 {
    background-color: var(--color-dark-70) !important;
  }

  .bg-dark-60 {
    background-color: var(--color-dark-60) !important;
  }

  .bg-dark-50 {
    background-color: var(--color-dark-50) !important;
  }

  .bg-dark-40 {
    background-color: var(--color-dark-40) !important;
  }

  .bg-dark-30 {
    background-color: var(--color-dark-30) !important;
  }

  .bg-dark-20 {
    background-color: var(--color-dark-20) !important;
  }

  .bg-dark-10 {
    background-color: var(--color-dark-10) !important;
  }

  .bg-dark-3 {
    background-color: var(--color-dark-3) !important;
  }

  /* Background Colors Primary */
  .bg-primary-100 {
    background-color: var(--color-primary-100) !important;
  }

  .bg-primary-90 {
    background-color: var(--color-primary-90) !important;
  }

  .bg-primary-80 {
    background-color: var(--color-primary-80) !important;
  }

  .bg-primary-70 {
    background-color: var(--color-primary-70) !important;
  }

  .bg-primary-60 {
    background-color: var(--color-primary-60) !important;
  }

  .bg-primary-50 {
    background-color: var(--color-primary-50) !important;
  }

  .bg-primary-40 {
    background-color: var(--color-primary-40) !important;
  }

  .bg-primary-30 {
    background-color: var(--color-primary-30) !important;
  }

  .bg-primary-20 {
    background-color: var(--color-primary-20) !important;
  }

  .bg-primary-10 {
    background-color: var(--color-primary-10) !important;
  }

  .bg-primary-5 {
    background-color: var(--color-primary-5) !important;
  }

  .bg-primary-3 {
    background-color: var(--color-primary-3) !important;
  }

  /* Background Colors Secondary */
  .bg-secondary-100 {
    background-color: var(--color-secondary-100) !important;
  }

  .bg-secondary-90 {
    background-color: var(--color-secondary-90) !important;
  }

  .bg-secondary-80 {
    background-color: var(--color-secondary-80) !important;
  }

  .bg-secondary-70 {
    background-color: var(--color-secondary-70) !important;
  }

  .bg-secondary-60 {
    background-color: var(--color-secondary-60) !important;
  }

  .bg-secondary-50 {
    background-color: var(--color-secondary-50) !important;
  }

  .bg-secondary-40 {
    background-color: var(--color-secondary-40) !important;
  }

  .bg-secondary-30 {
    background-color: var(--color-secondary-30) !important;
  }

  .bg-secondary-20 {
    background-color: var(--color-secondary-20) !important;
  }

  .bg-secondary-10 {
    background-color: var(--color-secondary-10) !important;
  }

  /* Background Colors Warning */
  .bg-warning-100 {
    background-color: var(--color-warning-100) !important;
  }

  .bg-warning-90 {
    background-color: var(--color-warning-90) !important;
  }

  .bg-warning-80 {
    background-color: var(--color-warning-80) !important;
  }

  .bg-warning-70 {
    background-color: var(--color-warning-70) !important;
  }

  .bg-warning-60 {
    background-color: var(--color-warning-60) !important;
  }

  .bg-warning-50 {
    background-color: var(--color-warning-50) !important;
  }

  .bg-warning-40 {
    background-color: var(--color-warning-40) !important;
  }

  .bg-warning-30 {
    background-color: var(--color-warning-30) !important;
  }

  .bg-warning-20 {
    background-color: var(--color-warning-20) !important;
  }

  .bg-warning-10 {
    background-color: var(--color-warning-10) !important;
  }

  /* Background Colors Danger */
  .bg-danger-100 {
    background-color: var(--color-danger-100) !important;
  }

  .bg-danger-90 {
    background-color: var(--color-danger-90) !important;
  }

  .bg-danger-80 {
    background-color: var(--color-danger-80) !important;
  }

  .bg-danger-70 {
    background-color: var(--color-danger-70) !important;
  }

  .bg-danger-60 {
    background-color: var(--color-danger-60) !important;
  }

  .bg-danger-50 {
    background-color: var(--color-danger-50) !important;
  }

  .bg-danger-40 {
    background-color: var(--color-danger-40) !important;
  }

  .bg-danger-30 {
    background-color: var(--color-danger-30) !important;
  }

  .bg-danger-20 {
    background-color: var(--color-danger-20) !important;
  }

  .bg-danger-10 {
    background-color: var(--color-danger-10) !important;
  }

  /* Background Colors Fancy */
  .bg-fancy-lime {
    background-color: var(--color-fancy-lime) !important;
  }

  .bg-fancy-sky {
    background-color: var(--color-fancy-sky) !important;
  }

  .bg-fancy-pink {
    background-color: var(--color-fancy-pink) !important;
  }

  .bg-pastel-100 {
    background-color: var(--color-pastel-100) !important;
  }

  .bg-pastel-90 {
    background-color: var(--color-pastel-90) !important;
  }

  .bg-pastel-80 {
    background-color: var(--color-pastel-80) !important;
  }

  .bg-pastel-70 {
    background-color: var(--color-pastel-70) !important;
  }

  .bg-pastel-60 {
    background-color: var(--color-pastel-60) !important;
  }

  .bg-pastel-50 {
    background-color: var(--color-pastel-50) !important;
  }

  .bg-pastel-40 {
    background-color: var(--color-pastel-40) !important;
  }

  .bg-pastel-30 {
    background-color: var(--color-pastel-30) !important;
  }

  .bg-pastel-20 {
    background-color: var(--color-pastel-20) !important;
  }

  .bg-pastel-10 {
    background-color: var(--color-pastel-10) !important;
  }

  .bg-pastel-3 {
    background-color: var(--color-pastel-3) !important;
  }

  .text-light-100 {
    color: var(--color-light-100) !important;
  }

  .hover\:bg-dark-3:hover {
    background-color: var(--color-dark-3);
  }

  .hover\:bg-dark-10:hover {
    background-color: var(--color-dark-10);
  }

  .hover\:bg-dark-20:hover {
    background-color: var(--color-dark-20);
  }

  .hover\:bg-dark-50:hover {
    background-color: var(--color-dark-50);
  }

  .hover\:text-light-100:hover {
    color: var(--color-light-100);
  }

  /*  Gradients  */
  --gradient-primary-40-primary-30: radial-gradient(
    184.57% 184.57% at 85.12% -17.26%,
    var(--color-primary-40) 0%,
    var(--color-primary-40) 100%
  );

  @media (prefers-color-scheme: dark) {
    /* TODO: Override Colors */
  }
}
