@font-face {
  font-family: 'Geist';
  src: url('src/assets/font/statics-ttf/Geist-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Geist';
  src: url('src/assets/font/statics-ttf/Geist-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Geist';
  src: url('src/assets/font/statics-ttf/Geist-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Geist';
  src: url('src/assets/font/statics-ttf/Geist-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Geist';
  src: url('src/assets/font/statics-ttf/Geist-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Geist';
  src: url('src/assets/font/statics-ttf/Geist-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Geist';
  src: url('src/assets/font/statics-ttf/Geist-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Geist';
  src: url('src/assets/font/statics-ttf/Geist-UltraBlack.ttf')
    format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Geist';
  src: url('src/assets/font/statics-ttf/Geist-UltraLight.ttf')
    format('truetype');
  font-weight: 200;
  font-style: normal;
}

/* Pour la police variable */
@font-face {
  font-family: 'GeistVF';
  src: url('src/assets/font/variable-ttf/GeistVF.ttf') format('truetype');
  font-weight: 100 900; /* Plage de poids pour la police variable */
  font-style: normal;
}
